/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { defineNuxtRouteMiddleware, navigateTo, useNuxtApp } from '#app'
import useService from '~/src/UserInterface/App/composables/Container/useService'
import useEngagementState from '~/src/UserInterface/Engagement/composables/useEngagementState'
import useUserState from '~/src/UserInterface/Identity/composables/useUserState'
import useOrganisationState from '~/src/UserInterface/Organisation/composables/useOrganisationState'

/**
 * This middleware must be called on the Index page, on the "/" url.
 *
 * Prerequisites for this middleware:
 * - The user must be authenticated as a regular user
 * - The user must have an organisation
 * - The user must have an active engagement
 *
 * This middleware redirects the user to the GL Overview page.
 *
 * @TODO:
 * - Check active engagement  for which modules are used (e.g. bank, gl)
 * - Check active engagement for which modules data requests have been processed
 * - Navigate to the index page of a module which has processed data
 */
export default defineNuxtRouteMiddleware(async () => {
  const logger = useService('logger')
  logger.info('[LANDING] Middleware fired')

  const nuxtApp = useNuxtApp()
  const { isAuthenticated } = useUserState()
  if (!isAuthenticated.value) {
    logger.info('[LANDING] User is not authenticated. Redirecting to the login page.')

    // eslint-disable-next-line ts/no-unsafe-argument
    return navigateTo(nuxtApp.$localePath('identity-Login'))
  }

  const uuidEncoder = useService('uuidEncoder')

  const { getOrganisation, hasOrganisation } = useOrganisationState()
  const { hasCurrentEngagement, getCurrentEngagement, hasCurrentPhase, getCurrentPhase } = useEngagementState()
  if (hasOrganisation.value && hasCurrentEngagement.value && hasCurrentPhase.value) {
    const organisation = getOrganisation()
    const organisationId = uuidEncoder.encode(
      organisation.value['@id'].toId(),
    )
    const organisationSlug = organisation.value.getSlug().toString()

    const engagement = getCurrentEngagement()
    const engagementId = uuidEncoder.encode(engagement.value['@id'].toId())
    const engagementSlug = engagement.value.getSlug().toString()

    const phase = getCurrentPhase()
    const phaseId = uuidEncoder.encode(phase.value['@id'].toId())
    const phaseSlug = phase.value.type.toString()

    return navigateTo(
      // eslint-disable-next-line ts/no-unsafe-argument
      nuxtApp.$localePath({
        name: 'dashboard-Index',
        params: {
          organisationId,
          organisationSlug,
          engagementId,
          engagementSlug,
          phaseId,
          phaseSlug,
        },
      }),
    )
  }

  if (hasOrganisation.value) {
    logger.info('[LANDING] Redirect user to engagement settings page')

    const organisation = getOrganisation()
    const organisationId = uuidEncoder.encode(
      organisation.value['@id'].toId(),
    )
    const organisationSlug = organisation.value.getSlug().toString()

    return navigateTo(
      // eslint-disable-next-line ts/no-unsafe-argument
      nuxtApp.$localePath({
        name: 'settings-organisation-Engagement',
        params: {
          organisationId,
          organisationSlug,
        },
      }),
    )
  }

  const { getUser } = useUserState()
  const user = getUser()
  const userId = uuidEncoder.encode(user['@id'].toId())
  const userSlug = user.getSlug().toString()

  logger.info('[LANDING] Redirect user to profile settings page')

  return navigateTo(
    // eslint-disable-next-line ts/no-unsafe-argument
    nuxtApp.$localePath({
      name: 'settings-profile-Profile',
      params: {
        userId,
        userSlug,
      },
    }),
  )
})
